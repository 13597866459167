/* PRODUCT CARDS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.cardcontainer {
  /* background: lightblue; */
  padding-bottom: 2rem;
  display: flex;
  align-items: center;
  /* width: 110%; */
  width: 100%;
  min-height: 100%;
  max-height: 100%;
}

.productcard {
  align-self: stretch;
  min-height: 100%;
  max-height: 100%;
  width: 100%;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.15), 0px 0px 50px rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
}

.productimgcontainer {
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20vh;
  /* min-height: 20vh;
  max-height: 20vh; */
  background: blueviolet;
}

.productimg {
  width: 100%;
  min-height: 20vh;
  max-height: 20vh;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  object-fit: cover;
  z-index: 2;
}

.info {
  text-align: center;
}

.info h1 {
  padding: 0.5rem 0rem 0rem 0rem;
  font-size: 0.85rem;
  margin: 0rem;
}

.info h2 {
  padding: 0rem 0.75rem;
  font-size: 0.55rem;
  color: #6a6a6a;
  /* font-weight: lighter; */
}

.info h5 {
  padding: 0.25rem 0rem;
  font-size: 1.25rem;
  margin: 0rem;
}

.controls {
  display: flex;
  justify-content: center;
}

.controls button {
  padding: 0rem 0.15rem;
  border: none;
  background: none;
  cursor: pointer;
  color: #525252;
  outline: none;
}

button.active {
  color: rgb(0, 0, 0);
}

.details {
  margin-top: 0.5rem;
  padding: 0rem 4rem;
  margin-bottom: 1rem;
}

.details button {
  width: 100%;
  padding: 0.5rem 0rem;
  background: #0e63bd;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 2rem;
  font-weight: bolder;
  margin-top: auto;
}
