main {
  min-height: 80vh; }

.company-logo-overlay {
  /* position: relative; */
  width: 100%;
  height: 100%;
  z-index: 3; }

.top-layer {
  z-index: 11; }

/* Pagination */
.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center; }

/* Buttons Custom */
.button {
  display: inline-block;
  background: #161718;
  color: #ccc;
  padding: 0.4rem 1.3rem;
  border: none;
  cursor: pointer;
  font-size: 1.66rem;
  font-weight: 700;
  margin-right: 0.5rem;
  outline: none;
  transition: all 0.3s ease-in; }
  .button.btn-primary {
    background-color: #165a84;
    color: #fff; }
    .button.btn-primary:hover {
      background: #1d8ad3; }
  .button.btn-dark {
    background-color: #343a40;
    color: #fff;
    margin: 0rem; }
    .button.btn-dark:hover {
      background: #3f474e; }
  .button.btn-light {
    background-color: #f4f4f4;
    color: #333; }
    .button.btn-light:hover {
      background: #b4b4b4; }
  .button.btn-success {
    background-color: #28a745;
    color: #fff; }
    .button.btn-success:hover {
      background: #2dbc4e; }
  .button.btn-danger {
    background-color: #dc3545;
    color: #fff; }
    .button.btn-danger:hover {
      background: #e04b59; }
  .button:hover {
    background: #626d78;
    color: #fff; }
  .button.btn-act, .button.btn-act2 {
    background-color: #1b6a9e;
    color: #fff; }
  .button.btn-act:hover, .button.btn-act2:hover {
    background: #c4c4c4; }
  .button.btn-round {
    background-color: #1b6a9e;
    color: #fff;
    border-radius: 1rem; }
    .button.btn-round:hover {
      background: #41a0de; }

.rating span {
  margin: 0.1rem; }

h1 {
  font-size: 1.85rem;
  padding: 1rem 0; }

h2 {
  font-size: 1.45rem;
  padding: 0.5rem 0; }

h3 {
  padding: 1rem 0; }

.company-color {
  color: #0e63bd; }

.company-name {
  font-size: 1.2rem; }

.icon-active {
  color: grey; }

.icon-inactive {
  color: lightgrey; }

.icon-success {
  color: green; }

.icon-danger {
  color: red; }

/* LANDING PAGE */
#landing-container {
  position: absolute;
  left: 0;
  top: 9.75%;
  height: 100vh;
  width: 100%;
  z-index: 2;
  background: url(https://res.cloudinary.com/beatshopper/image/upload/v1623684512/BeatShopperLandingBG01A_t5fjqe.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

.landing-cta {
  position: absolute;
  width: 45%;
  left: 4%;
  top: 20%;
  font-family: 'Barlow Condensed', sans-serif; }

.landing-cta h1 {
  text-align: center;
  font-size: 4rem;
  color: #050505; }

.landing-cta h2 {
  text-align: center;
  font-size: 2rem;
  color: #050505; }

.landing-enter-button {
  text-align: center;
  margin-top: 2rem; }

/* CTA Blocks Section */
.cta-container {
  position: absolute;
  top: 109%;
  left: 0;
  min-width: 100%;
  height: 55rem;
  background: url(https://res.cloudinary.com/beatshopper/image/upload/v1624508553/BeatShopperLandingBG2BelowFoldBlue_qe6vlr.jpg);
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 2; }

.cta-blocks {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin: auto;
  margin-top: -10rem; }

.cta-block1,
.cta-block2 {
  text-align: center;
  height: 25rem;
  width: 25%;
  border-radius: 0.25rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: all 1.25s ease-in-out; }
  .cta-block1:hover,
  .cta-block2:hover {
    transform: scale(1.025); }
  .cta-block1 h1,
  .cta-block2 h1 {
    color: #fff;
    font-size: 2.25rem;
    padding-top: 2rem; }
  .cta-block1 p,
  .cta-block2 p {
    padding: 0rem 2rem 2rem 2rem;
    color: #fff;
    font-size: 1.1rem; }

.cta-block1 {
  background: #cfcfcf; }
  .cta-block1 h1 {
    color: #272727; }
  .cta-block1 p {
    color: #272727; }

.cta-block2 {
  background: #202020; }

.cta {
  position: relative;
  width: 100%;
  padding-top: 15rem;
  text-align: center;
  z-index: 3; }
  .cta h1 {
    margin-top: 10rem;
    font-size: 4rem;
    color: #000; }

/* Carousel */
.carousel-image {
  width: 100%;
  min-height: 40vh;
  max-height: 40vh;
  object-fit: cover; }

.carousel-caption {
  position: absolute;
  bottom: 0; }

.carousel-caption h5 {
  color: #fff;
  text-shadow: 1px 1px #5e5e5e; }

.carousel-caption h6 {
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  color: #fff;
  text-shadow: 1px 1px #5e5e5e; }

.product-image-container {
  /* position: absolute; */
  max-width: 100%;
  width: auto;
  height: auto; }

.img-cover {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 33vh;
  max-height: 33vh;
  object-fit: cover;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.133), 0px 0px 50px rgba(0, 0, 0, 0.133);
  border-radius: 0.5rem; }

/* MEDIA QUERIES */
@media (max-width: 1599px) {
  .cta-blocks h1 {
    font-size: 1.35rem; }
  .cta-blocks p {
    font-size: 1rem; } }

@media (min-width: 900px) and (max-width: 1251px) {
  .cta-blocks h1 {
    font-size: 1.25rem; }
  .cta-blocks p {
    font-size: 0.75rem; } }

@media (min-width: 701px) and (max-width: 899px) {
  .cta-blocks h1 {
    font-size: 1rem; }
  .cta-blocks p {
    font-size: 0.6rem; } }

/*  OVER 990PX  */
@media (min-width: 991px) {
  .carousel-container {
    width: 50%;
    margin: auto; }
  .carousel-image {
    width: 100%;
    min-height: 20vh;
    max-height: 20vh;
    object-fit: cover; } }

/* UNDER 990PX */
@media (max-width: 990px) {
  .hide-sm {
    display: none; }
  .landing-cta h1 {
    text-align: center;
    font-size: 2rem;
    color: #050505; }
  .landing-cta h2 {
    text-align: center;
    font-size: 1rem;
    color: #050505; } }

/* UNDER 700 */
@media (max-width: 699px) {
  #landing-container {
    top: 88.75px;
    background: url(https://res.cloudinary.com/beatshopper/image/upload/v1624125946/BeatShopperLandingBGMobileWhite_xnhcab.jpg);
    background-position: 57.5%;
    background-size: cover;
    background-repeat: no-repeat; }
  .landing-cta {
    width: 100%;
    left: 0%;
    top: 0%;
    padding: 1.5rem; }
  .landing-cta h1 {
    font-size: 2.25rem; }
  .landing-cta h2 {
    margin-top: 1rem;
    font-size: 1.5rem; }
  .landing-enter-button {
    text-align: center;
    margin-top: 3rem; }
  .cta-container {
    background: #fff; }
  .cta-blocks {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
    margin-top: 0rem; }
  .cta-block1,
  .cta-block2 {
    text-align: center;
    height: 32rem;
    width: 100%;
    border-radius: 0rem; }
    .cta-block1 h1,
    .cta-block2 h1 {
      font-size: 2.5rem; }
    .cta-block1 p,
    .cta-block2 p {
      padding: 2rem;
      font-size: 1.2rem; }
  .cta {
    display: none; } }
